import { FC,  useEffect } from 'react';
import { SegmentCondition, Property } from '@/interfaces';
import { useState, useRef } from 'react';
import { on } from 'events';

interface SegmentConditionFormProps {
    condition: SegmentCondition;
    currentProperty: Property;
    properties: Property[];
    onSave: (condition: SegmentCondition) => void;
}

// Change operator dropdown based on field
const getOperatorOptions = (field: string, selected: string): JSX.Element => {
    switch (field) {
        case 'str':
            return (
                <>
                <option value="=" selected={selected==="="}>Equals</option>
                <option value="!=" selected={selected==="!="} >Not equals</option>
                <option value="contains" selected={selected==="contains"}>Contains</option>
                <option value="not_contains"  selected={selected==="not_contains"}>Does not contain</option>
                <option value="is_empty"  selected={selected==="is_empty"}>Is empty</option>
                <option value="not_empty"  selected={selected==="not_empty"}>Is not empty</option>
                </>
            );
            case 'strs':
                return (
                <>
                <option value="eq" selected={selected === "eq"}>All of</option>
                <option value="not_contains" selected={selected === "not_contains"}>None of</option>
                <option value="intersect" selected={selected === "intersect"}>One or more of</option>
                <option value="neq" selected={selected === "neq"}>Not equal to</option>
                <option value="is_empty"  selected={selected==="is_empty"}>Is empty</option>
                <option value="not_empty"  selected={selected==="not_empty"}>Is not empty</option>
                </>
            );
        }
        return (
            <>
            <option value="=" selected={selected === "="}>Equals</option>
            <option value="!=" selected={selected === "!="}>Not equals</option>
            <option value=">" selected={selected === ">"}>Greater than</option>
            <option value="<" selected={selected === "<"}>Less than</option>
            <option value="between" selected={selected === "between"}>Between</option>
            <option value="is_empty"  selected={selected==="is_empty"}>Is empty</option>
            <option value="not_empty"  selected={selected==="not_empty"}>Is not empty</option>
        </>
    );
};

// Render property dropdown.
const PropertyDropdown: FC<{properties: Property[], currentProperty: Property, onUpdate: (propertyId: number) => void}> = ({properties, currentProperty, onUpdate}) => {
    return (
        <div className='level'>
            <div className='level-left'>
                <div className='level-item'>
                    <div className="select">
                        <select defaultValue={currentProperty?.id || 
                        properties[0].id} onChange={(evt) => {
                            onUpdate(parseInt(evt.target.value + ""));
                        }}>
                            <option value={0}>Select a property</option>
                            {properties.map((property: Property, idx: number) => {
                                return <option key={idx} value={property.id}>{property.label}</option>
                            })}
                        </select>
                    </div>
                </div>
                <div className='level-item'>
                    <span>{currentProperty ? `(${currentProperty?.value_type})` : ''}</span>
                </div>
            </div>
        </div>
    );
}

export const SegmentConditionForm: FC<SegmentConditionFormProps> = ({ condition, properties, currentProperty, onSave }) => {
    const [operatorOptions, setOperatorOptions] = useState(<></>);
    const [propertyDropdown, setPropertyDropdown] = useState(<></>);
    const [defOperator, setDefOperator] = useState(condition.op);
    const once = useRef(false);

    // Value 2 is only relevant for integer fields and non-equal operators.
    let value2 = <></>;
    if (currentProperty?.value_type === 'int' && condition.op === 'between') {
        value2 = <div className="field">
            <label className="label">and</label>
            <input className="input" type="number" defaultValue={condition.v2} onChange={(evt) => {
                onSave({
                    ...condition,
                    v2: evt.target.value
                });
            }} />
        </div>;
    }
    
    useEffect(() => {
        if (!once.current) {
            setPropertyDropdown(<PropertyDropdown properties={properties} currentProperty={currentProperty} onUpdate={(propertyId: number) => {
                const prop = properties.find((prop) => prop.id === propertyId);
                onSave({
                    ...condition,
                    field: prop?.value_type+'',
                    pt: propertyId
                });
                setOperatorOptions(getOperatorOptions(prop?.value_type+"", defOperator));
                setDefOperator(condition.pt? defOperator : '=');
            }} />)
            console.log('currentProperty', currentProperty);
            setOperatorOptions(getOperatorOptions(currentProperty?.value_type+"", condition.op));
            once.current = true;
        }
    }, [condition, properties, currentProperty, defOperator, setOperatorOptions, setPropertyDropdown, onSave]);
    return (
        <div>
            <div className="field">
                <label className="label">Property</label>
                <div className='level'>
                    <div className='level-left'>
                        <div className='level-item'>
                            {propertyDropdown}
                        </div>
                        <div className='level-item'>
                            <span>{currentProperty ? `(${currentProperty?.value_type})` : ''}</span>
                        </div>
                    </div>
                </div>
            </div>

            {currentProperty?.value_type && <div className="field">
                <label className="label">Operator</label>
                <div className="select">
                <select defaultValue={condition.op} onChange={(evt) => {
                    setDefOperator(evt.target.value);
                    onSave({
                        ...condition,
                        op: evt.target.value
                    });
                }}>
                    {operatorOptions}
                </select>
                </div>
            </div>}
            <div className="field">
                <label className="label">Value</label>
                <div className="control">
                    <input className="input" type="text" defaultValue={condition.v1} onChange={(evt) => {
                        onSave({
                            ...condition,
                            v1: evt.target.value
                        });
                    }} />
                </div>
            </div>
            {value2}
        </div>

    )
};

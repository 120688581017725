import React, { useEffect, useRef, useState } from 'react';
import './App.scss';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import arrigoo from './arrigoo';
import { PropertiesPage } from '@/pages/PropertiesPage';
import { PropertyFormPage } from '@/pages/PropertyFormPage';
import { EventSpecPage } from '@/pages/EventSpecPage';
import { EventLogPage } from './pages/EventLogPage';
import { EventTestPage } from '@/pages/EventTest';
import { SegmentDetailPage } from './pages/SegmentDetailPage';
import { UserPage } from '@/pages/UserPage';
import { Layout } from '@/pages/Layout';
import { Login } from '@/pages/Login';
import { SegmentsSpecPage } from '@/pages/SegmentsSpecPage';
import { SegmentsSpecFormPage } from '@/pages/SegmentsSpecFormPage';
import { ProfilePage } from './pages/ProfilePage';
import { ProfileLatestIdentifiedPage } from './pages/ProfileLatestIdentified';
import { ProfileSinglePage } from './pages/ProfileSinglePage';
import { NoPage } from '@/pages/NoPage';
import { Dashboard } from '@/pages/Dashboard';
import { AccountSettingsPage } from '@/pages/AccountSettingsPage';
import { AccountLimitsPage } from './pages/AccountLimitsPage';
import { ActionsPage } from './pages/ActionsPage';
import { ActionsFormPage } from './pages/ActionsFormPage';
import { SecretsPage } from './pages/SecretsPage';

const devApi = localStorage.getItem('apiHost') || 'https://test.arrigoo.io';
const api = window.location.hostname.indexOf('localhost') >= 0 ? devApi : '';
const argoo = new arrigoo(api, '');
argoo.cdpClient.setApiKey(argoo.storage.token);
if (window.location.pathname === '/') {
    window.location.href = '/admin';
}
function App() {
    const [apiKey, setApiKey] = useState<string>(argoo.storage.token || '');
    const [apiRenew, setApiRenew] = useState<string>(argoo.storage.refresh);
    //const [userRole, setUserRole] = useState<string>(argoo.storage.role || '');

    const once = useRef(false);
    useEffect(() => {
        setApiKey(argoo.storage.token);
        if (!once.current && argoo.storage.refresh) {
            console.log('setting api key', argoo.storage.token);
            setApiRenew(argoo.storage.refresh);
            argoo.cdpClient.setRefreshToken(argoo.storage.token);
        }
        if (!once.current && !!argoo.storage.token && argoo.storage.token !== '') {
            argoo.cdpClient.setApiKey(argoo.storage.token);
            once.current = true;
        }
    }, []);

    if ((argoo.storage?.refresh === '') || !(argoo.storage?.refresh)) {
        return <Login cdpClient={argoo.cdpClient} authenticated={(token: string, refreshToken: string, role: string) => {
            argoo.cdpClient.setApiKey(token)
            argoo.storage.token = token;
            argoo.storage.refresh = refreshToken;
            argoo.storage.role = role;
            setApiRenew(refreshToken);
            setApiKey(token);
            //setUserRole(role);
            
            setTimeout(() => {
                argoo.cdpClient.refresh(
                    refreshToken,
                    (response: any) => { setApiKey(response.token); argoo.storage.token = response.token; },
                    (error: any) => { console.log('error', error); setApiKey(''); argoo.storage.token = null; window.location.href = '/admin'; }
                );
            }, 1000 * 5);
        }} />;
    }
    
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/admin" element={<Layout logout={() => {
                    setApiKey('');
                    argoo.cdpClient.setApiKey('');
                    argoo.storage.token = null;
                    argoo.storage.refresh = null;
                }} />}>
                    <Route index element={<Dashboard argoo={argoo} />} />
                    <Route path="/admin/event-test" element={<EventTestPage argoo={argoo} />} />
                    <Route path="/admin/event-log" element={<EventLogPage cdpClient={argoo.cdpClient} />} />
                    <Route path="/admin/event-specs" element={<EventSpecPage cdpClient={argoo.cdpClient} />} />
                    <Route path="/admin/property-specs" element={<PropertiesPage cdpClient={argoo.cdpClient} />} />
                    <Route path="/admin/property-specs/:pid" element={<PropertyFormPage cdpClient={argoo.cdpClient} />} />
                    <Route path="/admin/segment-specs" element={<SegmentsSpecPage cdpClient={argoo.cdpClient} />} />
                    <Route path="/admin/segment-specs/:sid" element={<SegmentsSpecFormPage cdpClient={argoo.cdpClient} />} />
                    <Route path="/admin/segment/:sid" element={<SegmentDetailPage cdpClient={argoo.cdpClient} />} />
                    <Route path="/admin/profiles" element={<ProfilePage client={argoo.cdpClient} />} />
                    <Route path="/admin/profiles/latestidentified" element={<ProfileLatestIdentifiedPage client={argoo.cdpClient} />} />
                    <Route path="/admin/profiles/:cid" element={<ProfileSinglePage client={argoo.cdpClient} />} />
                    <Route path="/admin/actions" element={<ActionsPage client={argoo.cdpClient} />} />
                    <Route path="/admin/actions/:aid" element={<ActionsFormPage client={argoo.cdpClient} />} />
                    <Route path="/admin/account/secrets" element={<SecretsPage client={argoo.cdpClient} />} />
                    <Route path="/admin/account" element={<AccountSettingsPage argoo={argoo} />} />
                    <Route path="/admin/account/users" element={<UserPage cdpClient={argoo.cdpClient} />} />
                    <Route path="/admin/account/limits" element={<AccountLimitsPage cdpClient={argoo.cdpClient} />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;

import React, { useState } from "react";
import { Property, PropCondition, EventSpec } from "@/interfaces";
import { PropConditionForm } from "./PropertyConditionForm";
import { IconClose, IconSave } from "@/components/Icons";
import { Link } from "react-router-dom";

interface PropertyFormProps {
    property: Property;
    eventSpecs: EventSpec[];
    onSave: (property: Property) => void;
}

const PropertyForm: React.FC<PropertyFormProps> = ({ property, eventSpecs, onSave }) => {
    const [selectedProperty, setSelectedProperty] = useState<Property>(property);
    const [eventRequirements, setConditions] = useState<PropCondition[]>(selectedProperty.event_requirements || []);
    const [valueType, setValueType] = useState<string>(selectedProperty.value_type);
    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const updatedProperty: Property = {
            ...selectedProperty,
            event_requirements: eventRequirements
        };
        console.log('pf', updatedProperty);
        onSave(updatedProperty);
    };
    const rendersysTitle = (title: string) => {
        const lowercaseTitle = title.toLowerCase();
        const formattedTitle = lowercaseTitle.replace(/[^a-z0-9]/g, "_");
        console.log('ft', formattedTitle);
        return formattedTitle;
    }
    return (        
        <div className="card property-form">
            <div className="form">
                <div className="card-content">
                    <div className="field">
                        <label className="label">Property name:</label>
                        <input
                            type="text"
                            className="input"
                            defaultValue={selectedProperty.label}
                            onChange={(event) => {
                                selectedProperty.label = event.target.value;
                                selectedProperty.sys_title = rendersysTitle(event.target.value);
                                setSelectedProperty(selectedProperty);
                            }}
                        />
                    </div>
                    <div className="field">
                        <label className="label">System name (lowercase and underscores only):</label>
                        <input
                            type="text"
                            className="input"
                            defaultValue={selectedProperty.sys_title}
                            onChange={(event) => {
                                selectedProperty.sys_title = event.target.value;
                                setSelectedProperty(selectedProperty);
                            }}
                        />
                    </div>
                    <div className="field">
                        <label className="label">Description:</label>
                        <textarea
                            className="textarea"
                            rows={2}
                            defaultValue={selectedProperty.description}
                            onChange={(event) => {
                                selectedProperty.description = event.target.value;
                                setSelectedProperty(selectedProperty);
                            }}
                        />
                    </div>
                    <div className="field">
                        <label className="label">Type of value:</label>
                        <div className="select">
                            <select
                                name="value_type"
                                className="select"
                                defaultValue={selectedProperty.value_type}
                                onChange={(event) => {
                                    console.log('vt', selectedProperty.value_type, event.target.value);
                                    selectedProperty.value_type = event.target.value;
                                    setSelectedProperty(selectedProperty);
                                    setValueType(event.target.value);
                                }}
                            >
                                <option value="str">String</option>
                                <option value="int">Number</option>
                                <option value="bool">Boolean</option>
                                <option value="strs">String List</option>
                                <option value="map">Map / Object</option>
                                <option value="ints">Number List</option>
                            </select>
                        </div>
                    </div>
                    <div className="field">
                        <label className="checkbox label">
                            <input type="checkbox" name="protected" defaultChecked={selectedProperty.protected}
                                onChange={(event) => {
                                    selectedProperty.protected = !!event.target.checked;
                                    setSelectedProperty(selectedProperty);
                                }}
                            />
                            {` Protected`}
                        </label>
                    </div>
                        
                        <div className="level">
                        <div className="level-left">
                        <div className="level-item">
                            <div className="field">
                                <label className="label">Identifier type:</label>
                                <div className="select">
                                    <select name="identifier_type" className="select" defaultValue={selectedProperty.identifier_type}
                                        onChange={(event) => {
                                            selectedProperty.identifier_type = event.target.value;
                                            setSelectedProperty(selectedProperty);
                                        }}
                                    >
                                        <option value="">None</option>
                                        <option value="email">Email</option>
                                        <option value="phone">Phone</option>
                                        <option value="foreignid1">Foreign ID 1</option>
                                        <option value="foreignid2">Foreign ID 2</option>
                                        <option value="foreignid3">Foreign ID 3</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="level-item">

                            <div className="field">
                                <label className="checkbox label">
                                    <input type="checkbox" name="identifier_merge" defaultChecked={selectedProperty.identifier_merge}
                                        onChange={(event) => {
                                            selectedProperty.identifier_merge = !!event.target.checked;
                                            setSelectedProperty(selectedProperty);
                                        }}
                                    />
                                    {` Identifier merge`}
                                </label>

                            </div>
                        </div>
                        </div>
                    </div>
                    <hr />
                    {eventRequirements.map((condition, index) => (
                        <div key={index}>
                            <PropConditionForm
                                onChange={(updatedCondition) => {
                                    const updatedConditions = [...eventRequirements];
                                    updatedConditions[index] = updatedCondition;
                                    setConditions(updatedConditions);
                                    selectedProperty.event_requirements = updatedConditions;
                                    setSelectedProperty(selectedProperty);
                                }}
                                eventSpecs={eventSpecs}
                                propertyCondition={condition}
                                valueType={valueType}
                            />
                        </div>
                    )
                    )}

                    <div className="buttons">
                        <button
                            onClick={handleSubmit}
                            className="button is-primary"
                            type="submit"
                        >Save &nbsp; <IconSave /></button>
                        <Link to='/admin/property-specs' className="button">Cancel &nbsp; <IconClose /></Link>
                    </div>
                </div>


            </div>
        </div>
    );
};

export default PropertyForm;

import { FC, useRef, useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Profile, EventI, Property, SegmentSpec } from '@/interfaces';
import CdpClient from '@/client';
import { EventTable } from '@/components/EventTable';
import { IconTrash, IconArrowCircleLeft } from '@/components/Icons';
import { Link } from 'react-router-dom';
import { DeleteProfileMessage } from '@/config/Messages';

function findPropertyValue(profile: any, property: Property): any {
    const prop = profile.p.find((p: any) => p.lab === property.sys_title);
    return JSON.stringify(prop ? prop.val : '');
}

function findSegmentLabel(segment: string, segments: SegmentSpec[]): string {
    const seg = segments.find((s: SegmentSpec) => s.sys_title === segment);
    return seg ? seg.title : segment;
}

// A page that displays a profile.
export const ProfileSinglePage: FC<{client: CdpClient}> = ({client}) => {
    const [profile, setProfile] = useState<any | null>(null);
    const [profileEvents, setProfileEvents] = useState<EventI[]>([]);
    const [propertySpecs, setPropertySpecs] = useState<Property[]>([]);
    const [segmentSpecs, setSegmentSpecs] = useState<SegmentSpec[]>([]);
    const once = useRef(false);
    const { cid } = useParams<{cid: string}>();
    const navigate = useNavigate();

    let queryString = new URLSearchParams(window.location.search);
    let tab = queryString.get('tab');
    if (!tab){
        tab="properties";
      }

    useEffect(() => {
        if (!once.current && cid) {
            // Get properties and segments first to have labels for the profile data.
            client.getProperties((response: any) => {
                setPropertySpecs(response);
                client.getSegments((segments: any) => {
                    setSegmentSpecs(segments);
                    client.lookupCustomer('cid', cid, (profile: any) => {
                        setProfile(profile);
                    });
                });
            });
            client.getEventsForCustomer(cid, '', (response: any) => {
                setProfileEvents(response);
            });
            once.current = true;
        }
    }, [cid, client]);
    if (!profile) {
        return (
            <div className='container'>   
                <div className="level-center">Fetching profile...</div>
            </div>
        )    
    }


    return (
        <div className='container'>   

            <div className="level">
                <div className="level-left">
                    <div className="level-item">
                        <h1 className="title">Profile details</h1>
                    </div>
                </div>
            </div>
            <div className='box'>
                <div className="tabs">
                  <ul>
                    <li className={tab==="properties" ? "is-active" : ""}><a href="?tab=properties">Properties</a></li>
                    <li className={tab==="segments" ? "is-active" : ""}><a href="?tab=segments">Segments</a></li>
                    <li className={tab==="events" ? "is-active" : ""}><a href="?tab=events">Events</a></li>
                    <li className={tab==="raw" ? "is-active" : ""}><a href="?tab=raw">Raw</a></li>
                  </ul>
                </div>

                {tab==="properties" && 
                <table className="table is-fullwidth is-striped is-hoverable">
                    <thead>
                        <tr>
                            <th>Property name</th>
                            <th>Property value</th>
                        </tr>
                    </thead>
                    <tbody>
                {propertySpecs.map((spec: Property, idx: number) => {
                    return (
                            <tr key={idx}>
                                <td>{spec.label}</td>
                                <td>{findPropertyValue(profile, spec)}</td>
                                </tr>
                    );
                })}
                    </tbody>
                </table>
                }   
              
                {tab==="segments" &&
                <table className="table is-fullwidth is-striped is-hoverable">
                    <thead>
                        <tr>
                            <th>Segment title</th>
                        </tr>
                    </thead>
                    <tbody>
                        {profile.s.map((seg: string, idx: number) => {
                            return (<tr key={idx}>
                                        <td>{findSegmentLabel(seg, segmentSpecs)}</td>
                                        </tr>)
                        })}
                    </tbody>
                </table>
                } 

                {tab==="raw" &&        
                    <pre>{JSON.stringify(profile, null, 2)}</pre>

                } 

                {tab==="events" &&  
                    <EventTable events={profileEvents}
                        onDelete={(id: number) => {
                            client.deleteEvent(id, () => {
                                client.getEventsForCustomer(profile?.cid+'', '', (response: any) => {
                                    setProfileEvents(response);
                                });
                            });
                        }} />
                } 

                </div>

                <div className='grid'>
                <div className='cell'>
                    <div className='buttons'>
                        <Link to={`/admin/profiles`}><button className='button'>Back &nbsp;<IconArrowCircleLeft /></button></Link>
                        <button className='button has-background-danger' onClick={() => {
                            if (window.confirm(DeleteProfileMessage)) {
                                client.deleteCustomer(
                                    profile.cid,
                                    (response: any) => {
                                        navigate('/admin/profiles');
                                    }
                                );
                            }
                            }}>Delete profile &nbsp;<IconTrash /></button>
                    </div>
                </div>
            </div>

        </div>
    );
}

import { FC, useState } from 'react';
import { Date } from './Date';
import { IconSearch, IconTrash, IconUser, IconEye, IconPencil,IconClose } from './Icons';
import { Link } from 'react-router-dom';

interface ProfileListItemProps {
    index: number;
    profile: any;
    onShowDetails: (key: number) => void;
    showDetails: boolean;
    onDelete: (cid: string) => void;
}

export const ProfileListItem: FC<ProfileListItemProps> = ({ index, profile, onShowDetails, showDetails, onDelete}) => {
    const [details, setDetails] = useState<boolean>(showDetails);
    return (
        <>
        <tr onClick={() => onShowDetails(index)}>
            <td>
                {(profile as any).i?.map((ident: any, idx: number) => {
                    return <span key={idx}>{ident.id_type}: {ident.id_value}</span>
              })}
            </td>
            <td>
                {(profile as any).s?.map((seg: any, idx: number) => {
                    return <span><span key={idx} className='tag is-warning'>{seg}</span> &nbsp;</span>
                })}
            </td>
            <td>
                <Date date={profile.ctime} format="short" />
            </td>
            <td>
                <Date date={profile.mtime} format="short" />
            </td>
            <td width="50">
                <div className="field has-addons">
                                            <p className="control">
                                                <Link to={`/admin/profiles/${profile.cid}`}><span className="button"><IconEye /></span></Link>
                                            </p>
                                            <p className="control">
                                                <button className='button' onClick={() => {onShowDetails(index); setDetails(!details)}}>
                                                    {!details ? <IconSearch /> : <IconClose />}
                                                </button>
                                            </p>
                                        </div>
            </td>
        </tr>
            {details &&
                <tr>
                    <td colSpan={6}>
                        <pre>{JSON.stringify(profile, null,2)}</pre>
                    </td>
                </tr>
            }
        </>
    );
}
import { FC, useRef, useEffect, useState } from 'react';
import { Profile } from '@/interfaces';
import CdpClient from '@/client';
import { IconTrash } from '@/components/Icons';

// A page that displays tools for monitoring consents.
export const ProfilePage: FC<{client: CdpClient}> = ({client}) => {
    const [profile, setProfile] = useState<Profile | null>(null);
    const [idType, setIdType] = useState<string>('cid');
    const [idValue, setIdValue] = useState<string>('');
    const once = useRef(false);
    const [totalProfiles, setTotalProfiles] = useState<number>(0);
    const [identifiedProfiles, setIdentifiedProfiles] = useState<number>(0);

    useEffect(() => {
        if (!once.current) {
            client.getStats('customer-total', {}, (response: any) => {
                const values = (Object.values(response)[0] as number[]);
                console.log('customer total', values);
                setTotalProfiles(values[0] || 0);
                setIdentifiedProfiles(values[1] || 0);
            });
            once.current = true;
        }
    });
    return (
        <div className='container'>
            <div className="level toolbar">
                <div className="level-left">
                    <div className="level-item">
                    <h2 className="title">Profiles</h2>
                    </div>
                </div>
            </div>
            <div className='grid'>
                <div className='cell'>
                    <div className='box'>
                        <h2 className='widget-label'>Total profiles/identified</h2>
                        <h1 className='widget-value'>{totalProfiles}/{identifiedProfiles}</h1>
                    </div>
                </div>
                <div className='cell'>
                    <div className='box'>
                        <h2 className='widget-label'>Identified</h2>
                        <h1 className='widget-value'>{((100*identifiedProfiles/totalProfiles)+"").slice(0,4)}%</h1>
                    </div>
                </div>
            </div>
            <div className='box'>
                <h1 className='subtitle'>Look up profile</h1>

                <div className='field'>
                    <label className='label'>Profile identifier type</label>
                    <div className='select'>
                    <select onChange={(evt) => setIdType(evt.target.value)}>
                        <option value="cid">Profile ID (CDP ID)</option>
                        <option value="email">Email</option>
                        <option value="phone">Phone number</option>
                        <option value="foreignid1">Foreign ID 1</option>
                        <option value="foreignid2">Foreign ID 2</option>
                        <option value="foreignid3">Foreign ID 3</option>
                    </select>
                    </div>
                </div>
                <div className='field'>
                    <input className='input' type='text' placeholder='Enter profile identifier' onChange={(evt) => setIdValue(evt.target.value)} />
                </div>
                <div className='buttons'>
                <button className='button is-primary' 
                    onClick={() => {
                        client.lookupCustomer(
                            idType,
                            idValue,
                            (response: Profile) => {
                                setProfile(response);
                            }
                        )
                    }}
                >Search</button>
                </div>
                
                {profile && (
                    <div>
                        <div className='buttons'>
                            <button className='button is-primary' onClick={() => setProfile(null)}>Clear</button>
                            <button className='button is-danger' onClick={() => {
                                client.deleteCustomer(
                                    profile.cid,
                                    (response: any) => {
                                        setProfile(null);
                                    }
                                );
                            }}>Delete &nbsp; <IconTrash /></button>
                        </div>
                        <h2 className='subtitle'>CID: {profile.cid}</h2>
                        <p>{profile.email}</p>
                        <p>{profile.phone}</p>
                        <pre>{JSON.stringify(profile, null, 2)}</pre>
                    </div>
                )}
            </div>
        </div>
    );
}

import { FC, useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Profile } from '@/interfaces';
import CdpClient from '../client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import { IconPencil, IconPlus, IconTrash } from '@/components/Icons';
import { ProfileListItem } from '@/components/ProfileListItem';

const ProfileLatestIdentifiedPage: FC<{client: CdpClient}> = ({client}) => {

    const [profile, setProfile] = useState<Profile | null>(null);
    const [idType, setIdType] = useState<string>('cid');
    const [idValue, setIdValue] = useState<string>('');
    const once = useRef(false);
    const [totalProfiles, setTotalProfiles] = useState<number>(0);
    const [identifiedProfiles, setIdentifiedProfiles] = useState<number>(0);
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [showProfile, setShowProfile] = useState<number>(-1);
    useEffect(() => {
        if (!once.current) {
            client.getStats('customer-total', {}, (response: any) => {
                const values = (Object.values(response)[0] as number[]);
                console.log('customer total', values);
                setTotalProfiles(values[0] || 0);
                setIdentifiedProfiles(values[1] || 0);
            });
            client.getCustomers((response: any) => {
                console.log('profiles', response);
                setProfiles(response);
            });

            once.current = true;
        }
    });
    return (
    <div className='container'>
        <div className="level toolbar">
            <div className="level-left">
                <div className="level-item">
                <h2 className="title">Latest identified profiles</h2>
                </div>
            </div>
        </div>
        <div className="box has-text-left">
           <table className='table is-fullwidth is-striped is-dark is-hoverable'>
                            <thead>
                                <tr>
                                    <th>Identifiers</th>
                                    <th>Segments</th>
                                    <th>Created</th>
                                    <th>Updated</th>
                                    <th className="has-text-centered">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                            {profiles.map((p: Profile, idx: number) => {
                                const isSelected = idx === showProfile;
                                return <ProfileListItem 
                                    key={idx} 
                                    index={idx} 
                                    profile={p} 
                                    onShowDetails={(key: number) => { setShowProfile(key)}} showDetails={isSelected}  
                                    onDelete={(cid: string) => {
                                        const profilelist = profiles.filter((p: Profile) => p.cid !== cid);
                                        setProfiles(profilelist);
                                   
                                    }}
                                />
                            })}
                            </tbody>
                        </table>
        </div >
    </div>
    )
}

export { ProfileLatestIdentifiedPage };
